<template>
  <LiefengContent
    @backClick="clickBar"
    isBack="true"
    :navList="navList"
    @clickBar="clickBar"
  >
    <template #title>{{ titleName }}</template>
    <template #toolsbarRight >
      <Select
        v-if="navList.length != 4 "
        v-model="selectValue"
        style="width: 200px"
        @on-select="changeSelect"
      >
        <Option
          v-for="(item, index) in selectList"
          :value="item.value"
          :key="index"
          >{{ item.label }}</Option
        >
      </Select>
    </template>
    <template #contentArea>
      <Card class="card-margin"  v-if="navList.length == 2 || navList.length == 3 || navList.length == 4">
        时间范围
        <Button style="margin-left:10px" @click="index = 0; getStatUserOnTime(7)"  :class="index == 0?'active':''">近7天</Button>
        <Button style="margin-left:10px" @click="index = 1; getStatUserOnTime(30)" :class="index == 1?'active':''">近30天</Button>
        <Button style="margin-left:10px" @click="index = 2;" :class="index == 2?'active':''">自定义</Button>
     
        <DatePicker
          :options='options'
          v-if="index == 2"
          type="daterange"
          placeholder="请选择自定义时间"
          format="yyyy/MM/dd"
          style="width: 200px;margin-left:10px  "
          v-model="searchDate"
        ></DatePicker>
        <Button style="margin-left:10px" type="primary" @click="changeSearch" v-if="index == 2">查询</Button>
     
        <Vline :data="userNum"></Vline>
      </Card>

      <Card class="card-margin" v-if="navList.length == 2 || navList.length == 3" >
        <TitleColor title="用户分布情况"></TitleColor>
        <div class="stree-div">
          <div class="left">
            <VeRing :ringData="userSpread" :listData='userList'></VeRing>
          </div>
          <div class="right">
            <VeTable
              :tableData="userSpreadData"
              :tableColumn="userSpreadColumn"
              :height='400'
            ></VeTable>
          </div>
        </div>
      </Card>

      <Card class="card-margin" v-if="navList.length == 2 || navList.length == 3">
        <TitleColor title="累计用户"></TitleColor>
        <VeBar :showSelect="false"   :data="allUser" ></VeBar>
      </Card>

      <Card class="card-margin" v-if="navList.length == 2 || navList.length == 3 || navList.length == 4">
        <TitleColor title="性别分布"></TitleColor>
        <div class="stree-div">
          <div class="left">
            <Ring :data="sexData" :extend="sexExtend"></Ring>
          </div>
          <div class="right">
            <VeTable :tableData="sexTData" height='400' :tableColumn="sexColumn"></VeTable>
          </div>
        </div>
      </Card>

      <Card class="card-margin" v-if="navList.length == 2 || navList.length == 3 || navList.length == 4">
        <TitleColor title="年龄分布"></TitleColor>
        <div class="stree-div">
          <div class="left">
            <Ring :data="ageData" :extend="ageExtend"></Ring>
          </div>
          <div class="right">
            <VeTable :tableData="ageTData" :height='400' :tableColumn="ageColumn"></VeTable>
          </div>
        </div>
      </Card>

      <Card class="card-margin" v-if="navList.length == 2 || navList.length == 3 || navList.length == 4">
        <TitleColor title="人群类型"></TitleColor>
        <Histogram :data="populationType"></Histogram>
      </Card>

      <Card class="card-margin" v-if="navList.length == 4">
        <TitleColor title="属地化情况"></TitleColor>
        <div class="stree-div">
          <div class="left">
            <Ring :data="localizationData" :extend="localizationExtend"></Ring>
          </div>
          <div class="right">
            <VeTable
              :tableData="localizationTData"
              :tableColumn="localizationColumn"
              :height='400'
            ></VeTable>
          </div>
        </div>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "../components/LiefengContent2.vue";
import Histogram from "v-charts/lib/histogram.common";
import TitleColor from "../components/TitleColor.vue";
import VeRing from "../components/VeRing";
import Ring from "v-charts/lib/ring.common";
import VeTable from "../components/Table";
import Bar from "v-charts/lib/bar.common";
import VeBar from '../components/VeBar'
import Vline from "v-charts/lib/line.common";
import { mixinRequest } from "../utils/mixinRequest.js";

export default {
  components: {
    LiefengContent,
    Histogram,
    TitleColor,
    VeRing,
    Ring,
    VeTable,
    Bar,
    Vline,
    VeBar
  },
  mixins: [mixinRequest],
  data() {
    return {
      navList: [],
      titleName: "用户分布情况",
      // 存放用户信息
      portalInfo: {},
      // 绑定的搜索参数
      selectValue: "",
      // 绑定的选择框列表
      selectList: [],


      index:0,
      searchDate:[],
      options: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() - 1000 * 60 * 60 * 24 * 1;
        }
      },

      userNum: {
        columns: ["时间", "新增用户", "累计用户"],
        rows: [],
      },

      //用户分布情况
      userSpread: {
        columns: ["示范", "用户数量"],
        rows: [
        ],
      },
      userList:{
        rows:[]
      },
      userSpreadColumn: [
        {
          title: "排名",
          key: "no",
          align: "center",
        },
        {
          title:"街道名称",
          key: "name",
          align: "center",
          render:(h,params) => {
            return h('div',[
              h('a',{
                on:{
                  'click':()=>{
                    // 点击跳转
                    let obj = {
                      label:params.row.name,
                      value:params.row.orgCode
                    }
                    this.changeSelect(obj)
                  }
                }
              },params.row.name)
            ])
          }
        },
        {
          title: "用户数",
          key: "num",
          align: "center",
        },
      ],
      userSpreadData: [],

      //地方累计用户
      allUser: {
        columns: ["地方", "累计用户", "昨日新增"],
        rows: [],
      },
      // allUserExtend: {
      //   stack: "total",
      // },

      // 人群类型
      populationType: {
        columns: ["类型", "人群类型"],
        rows: [],
      },

      // 性别分布
      sexData: {
        columns: ["性别", "用户数"],
        rows: [],
      },
      sexExtend: {
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
      sexColumn: [
            {
              title: "性别",
              key: "key",
              minWidth: 200,
              align: "center",
            },
            {
              title: "用户数",
              key: "value",
              minWidth: 200,
              align: "center",
            },
            {
              title: "占比",
              key: "ext",
              minWidth: 200,
              align: "center",
            },
          ],
      sexTData: [],

      //年龄分布
      ageData: {
        columns: ["年龄段", "数量"],
        rows: [ ],
      },
      ageColumn: [
        { title: "年龄段", key: "key",align: "center"},
        { title: "用户数", key: "value",align: "center" },
        { title: "占比", key: "ext",align: "center" },
      ],
      ageTData: [],
      ageExtend: {
        legend: {
          orient: "vertical",
          right: 100,
          top: 100,
        },
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
      

      //属地化情况
      localizationData: {
        columns: ["类型", "数量"],
        rows: [],
      },
      localizationExtend: {
        legend: {
          // type: 'scroll',
          orient: "vertical",
          right: 100,
          top: 100,
          // bottom: 20,
        },
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
      localizationColumn: [],
      localizationTData: [],
    };
  },
  async created(){
        this.portalInfo = this.$store.state.portal ? this.$store.state.portal : {}
        if(this.portalInfo){

            // 正常入口进
            if(this.portalInfo.orgLevel == 3 && !this.$route.query.orgCode){
                // 如果当前是区，进来是街道
                this.titleName = this.portalInfo.orgName + '用户分布情况'
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))

                this.navList.push(...(JSON.parse(this.$route.query.router)),{
                  name:this.titleName,
                  router:this.$route.path,
                  orgCode:this.portalInfo.orgCode
                })
                await this.getData(this.portalInfo.orgCode)
                console.log(this.navList)
            }else if(this.portalInfo.orgLevel == 3 && this.$route.query.orgCode){
                this.titleName = this.$route.query.orgName + '用户分布情况'
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.$route.query.orgCode))

                this.navList.push(...(JSON.parse(this.$route.query.router)),{
                  name:this.titleName,
                  router:this.$route.path,
                  orgCode:this.$route.query.orgCode
                })
                await this.getData(this.$route.query.orgCode)
            }
        }
        
    },
  methods: {

    //选择搜索
    async changeSelect(val){
      // console.log(val)
      this.searchDate = []
      this.index = 0
        if(this.navList.length == 2){
          // console.log(val)
          // console.log(this.titleName)
          this.titleName = val.label+"用户分布情况"
          //  console.log(this.titleName)
           this.selectList = []
          this.selectList = this.formatterData(await this.getSelectList(val.value))
          this.selectValue = ''
          this.navList.push({
              name:this.titleName,
              router:this.$route.path,
              orgCode:val.value
          })
          this.getData(val.value)
        }else if(this.navList.length == 3){

          this.titleName = val.label+"用户分布情况"
          this.selectValue = ''
          this.navList.push({
              name:this.titleName,
              router:this.$route.path,
              orgCode:val.value
          })
          this.getData(val.value)
        }
        console.log(this.navList)
    },

    async clickBar(item,index){
        if(!item){
          // 点击返回
          this.navList.splice(this.navList.length - 1,1)
          this.index = 0
          this.titleName = this.navList[this.navList.length-1].name
          this.selectValue = ''
          this.selectList =  this.formatterData(await this.getSelectList(this.navList[this.navList.length-1].orgCode))
          await this.getData(this.navList[this.navList.length-1].orgCode)
        }else{
          // 点击面包屑
          this.navList.splice(index + 1,99)
          this.index = 0
          this.titleName = item.name
          this.selectValue = ''
          this.selectList =  this.formatterData(await this.getSelectList(item.orgCode))
          await this.getData(item.orgCode)
        }
    },

    async getStatUserOnTime(data){
        this.userNum.rows = []
        let data1 = await this.getStatUserOnTimeByOrgCode(data,this.portalInfo.orgCode)
          data1.map(e => {
            this.userNum.rows.push(
              {时间:e.key,新增用户:e.ext,累计用户:e.value}
            )
          })
    },


    async getData(orgCode) {
      // 判断是否获取当了当前用户的信息
      if (this.portalInfo && this.portalInfo.orgLevel != "") {
        
        // 2区 3街道 4社区 
        if(this.navList.length == 2 || this.navList.length == 3 || this.navList.length == 4){
          // 用户累计新增折线图
          this.userNum.rows = []
          let data1 = await this.getStatUserOnTimeByOrgCode(7,orgCode)
          data1.map(e => {
            this.userNum.rows.push(
              {时间:e.key,新增用户:e.ext,累计用户:e.value}
            )
          })
        }

        if(this.navList.length == 2 || this.navList.length == 3){  
          //用户分布情况
          this.userSpreadData = []
          this.userSpreadColumn[1].title = this.navList.length == 2?'街道名称':'社区名称'
          let data2 = await this.getStatUserByOrgCode(orgCode)
          this.userSpread.rows = [
            {示范:'示范社区用户数',用户数量:data2.pilotUser},
            {示范:'试用社区用户数',用户数量:data2.trialUser},
            {示范:'其他社区用户数',用户数量:data2.otherUser}
          ],
          this.userList.rows = [
            {示范:'用户总数',用户数量:data2.userTotal},
            {示范:'示范社区用户数',用户数量:data2.pilotUser},
            {示范:'试用社区用户数',用户数量:data2.trialUser},
            {示范:'其他社区用户数',用户数量:data2.otherUser}
          ],
          data2.streetVoList.map( item => {
            this.userSpreadData.push(
              {no:item.no,name:item.orgName,num:item.totalOrgUser,orgCode:item.orgCode}
            )
          })
        }  

        if(this.navList.length == 2 || this.navList.length == 3){  
          // 街道累计用户
          this.allUser.rows = []
          let data3 = await this.getStatUserPortraitByOrgCode(orgCode)
          data3.map( item => {
            this.allUser.rows.push(
              {地方:item.key,累计用户:item.value, 昨日新增:item.ext}
            )
          })
        }

        if(this.navList.length == 2 || this.navList.length == 3 || this.navList.length == 4){
          // 性别分布
          this.sexData.rows = []
          this.sexTData = []
          let data4 = await this.getZoneCategoryByGender(orgCode)
          data4.map(e => {
            this.sexData.rows.push({
              '性别':e.key,'用户数':e.value
            })
            this.sexTData.push({
              key:e.key,value:e.value,ext:e.ext
            })
          })
        }

        if(this.navList.length == 2 || this.navList.length == 3 || this.navList.length == 4){
          // 年龄分布
          this.ageData.rows = []
          this.ageTData = []
          let data5 = await this.getZoneCategoryByAge(orgCode)
          data5.map(e => {
            this.ageData.rows.push({
              '年龄段':e.key,'数量':e.value
            })
            this.ageTData.push({
              key:e.key,value:e.value,ext:e.ext
            })
          })
        }

        if(this.navList.length == 2){
          // 人群类型
          this.populationType.rows = []
          let data6 = await this.getZoneUserByType(orgCode)
          data6.map(e => {
            this.populationType.rows.push({
              '类型':e.name,'人群类型':e.num
            })
          })
        }
        if(this.navList.length == 3){
          // 人群类型
          this.populationType.rows = []
          let data6 = await this.getStreetUserByType(orgCode)
          data6.map(e => {
            this.populationType.rows.push({
              '类型':e.name,'人群类型':e.num
            })
          })
        }
        if(this.navList.length == 4){
          // 人群类型
          this.populationType.rows = []
          let data6 = await this.getCommunityUserByType(orgCode)
          data6.map(e => {
            this.populationType.rows.push({
              '类型':e.name,'人群类型':e.num
            })
          })
        }

        if(this.navList.length == 4){
          let data7 = await this.getCompanyByCommunity(orgCode)
          this.localizationData.rows = [
            { "类型": "属地化单位", "数量": data7.regNum },
            { "类型": "服务主体", "数量": data7.serviceAmount },
          ]
          this.localizationColumn = [
            {title:'属地化名称',key:'companyName',align: "center"},
            {title:'所属类型',key:'type',align: "center"},
            {title:'登记时间',align: "center",
              render:(h,params) =>{
                return h('div',{},params.row.regTime ? this.$core.formatDate(
                  new Date(params.row.regTime),
                    "yyyy-MM-dd"
                  ):'')
              }
            },
            {title:'入驻时间',align: "center",
              render:(h,params) =>{
                return h('div',{},params.row.settleTime ? this.$core.formatDate(
                  new Date(params.row.settleTime),
                    "yyyy-MM-dd"
                  ):'')
              }
            },
          ]
          this.localizationTData = data7.dataByCommunityList 
        }
        }
    },

    async changeSearch(){
      if(this.searchDate[0] == ''){
          this.$Message.warning({
              content:'请选择日期后进行查询',
              background:true
          })
          return
      }
      this.userNum.rows = []
      let startDate = this.$core.formatDate(new Date(this.searchDate[0]),'yyyy-MM-dd')
      let endDate = this.$core.formatDate(new Date(this.searchDate[1]),'yyyy-MM-dd')
      let data1 = await this.getStatUserOnTimeByOrgCode('',this.navList[this.navList.length-1].orgCode,startDate,endDate)
          data1.map(e => {
            this.userNum.rows.push(
              {时间:e.key,新增用户:e.ext,累计用户:e.value}
            )
          })
    }, 
    },

     
  
};
</script>

<style lang="less" scoped>
.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.card-margin {
  margin-top: 30px;
}
.stree-div {
  display: flex;
  .left {
    width: 50%;
  }
  .right {
    margin-left: 20px;
    flex: 1;
  }
}
.active{
  color: #fff;
  background: #2d8cf0;
}
</style>





